










import BaseSite from '@/views/BaseSite.vue';
import Page from '@/components/elements/Page.vue';
import HomeContent from '@/components/contents/HomeContent.vue';
import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {
    Page,
    HomeContent
  },
})
export default class SiteHome extends BaseSite {
  private homeObject: object = {menuActive:false};

}
