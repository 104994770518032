
















import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
             components: {}
           })
export default class ContentShowCaseItem extends Vue {
  @Prop({}) protected imgSrc!: any;
  @Prop({default: ""}) protected showCaseTitle!: string;
  @Prop({default: false}) protected hasDescription!: boolean;
  @Prop({default: ""}) protected imgClass!: string;
  @Prop({default: ""}) protected imgColClass!: string;
  @Prop({default: ""}) protected linkTo!: string;

  withBtn() {
    return !!this.btnTo ? 'with-btn' : 'without-btn'
  }

}
