








































































































































































































































import ContentColumnsTextImage from '@/components/elements/ContentColumnsTextImage.vue';
import ContentSection from '@/components/elements/ContentSection.vue';
import ContentShowCaseItem from '@/components/elements/ContentShowCaseItem.vue';
import ContentThin from '@/components/elements/ContentThin.vue';
import NavRouterLinks from '@/components/elements/NavRouterLinks.vue';
import {Component, Prop, Vue} from "vue-property-decorator";
import VBtn from "vuetify/src/components/VBtn/VBtn";

@Component({
             components: {
               NavRouterLinks,
               VBtn,
               ContentSection,
               ContentThin, ContentColumnsTextImage, ContentShowCaseItem
             }
           })
export default class HomeContent extends Vue {
  @Prop({default: {menuActive: false}}) protected pageData!: object;
}
