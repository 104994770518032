



















































import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
             components: {}
           })
export default class ContentColumnsTextImage extends Vue {
  @Prop({default: "left"}) protected imgAlign!: string;
  @Prop({}) protected imgSrc!: any;
  @Prop({default: ""}) protected imgClass!: string;
  @Prop({default: ""}) protected imgColClass!: string;
  @Prop({default: ""}) protected textColClass!: string;
  @Prop({default: ""}) protected btnColClass!: string;
  @Prop({default: ""}) protected btnClass!: string;
  @Prop({default: "More"}) protected btnLabel!: string;
  @Prop({default: ""}) protected btnTo!: string;

  withBtn() {
    return !!this.btnTo ? 'with-btn' : 'without-btn'
  }

}
