import { render, staticRenderFns } from "./ContentColumnsTextImage.vue?vue&type=template&id=7e45e834&scoped=true&"
import script from "./ContentColumnsTextImage.vue?vue&type=script&lang=ts&"
export * from "./ContentColumnsTextImage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e45e834",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/florian/Projects/get-a-shop-website/vuetify_getashop_promote/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {VBtn,VFlex,VIcon,VImg,VLayout})
